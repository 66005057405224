import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import LoginForm from "../components/LoginForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`;

const Message = styled.p`
  color: red;
  font-size: 16px;
  margin-bottom: 20px;
  max-width: 400px;
`;

const Login = ({}) => {
  const location = useLocation();

  // Extract redirect URL from query parameters
  const queryParams = new URLSearchParams(location.search);
  const prompt = queryParams.get("prompt") === "true";

  return (
    <Container>
      {prompt && (
        <Message>
          Your session has expired. Please sign in again and ensure you consent to the required permissions.
        </Message>
      )}
      <LoginForm prompt={prompt} />
    </Container>
  );
};

export default Login;
