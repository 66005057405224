import React, { useState, useEffect, useContext } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  Link
} from "react-router-dom";
import styled from "styled-components";
import { useToast } from "./Toast";
import { AuthContext } from "../context/AuthProvider";
import { ItemsContext } from "../context/ItemsContext";
import { BackButton, EditButton, DeleteButton, SaveButton, CancelButton } from "./Buttons";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const DetailContainer = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 auto;
  gap: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  max-width: 500px;
  text-align: center;
`;

const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;
  max-width: 100px;

  @media (min-width: 768px) {
    margin-right: 40px;
  }
`;

const ThumbnailImage = styled.img`
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

const MainImage = styled.img`
  width: 95%;
  height: auto;
  max-width: 300px;
  margin: 0 auto;
  display: block;
`;

const TextContainer = styled.div`
  flex: 1;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-top: 5px;
  font-size: 16px;
  color: #333;
  text-align: center;

  h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    color: #555;
    margin: 8px 0;
    line-height: 1.6;
  }

  p strong {
    font-weight: 600;
    color: #333;
    margin-right: 8px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 30px;
`;

const InputField = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 100%;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
  }
`;

const SelectField = styled.select`
  padding: 12px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  width: 100%;
  font-size: 14px;
  
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const Chip = styled.div`
  display: inline-block;
  padding: 8px 16px;
  background-color: #f5f5f5;
  border-radius: 25px;
  margin-right: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  color: #333;
  position: relative;
  cursor: default;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

const ChipClose = styled.span`
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
  cursor: pointer;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const VisitStoreButton = styled.a`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #0056b3;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  gap: 15px;
  max-width: 700px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const MainImageContainer = styled.div`
  flex: 1;
  max-width: 600px;

  @media (max-width: 768px) {
    order: 1;
    max-width: 100%;
  }
`;

const StyledSwiper = styled(Swiper)`
  .swiper-button-next,
  .swiper-button-prev {
    color: black;
    width: 30px;
    height: 30px;
    
    &::after {
      font-size: 20px;
    }

    top: 50%;
    transform: translateY(-50%);
    
    @media (max-width: 768px) {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }

  .swiper-pagination-bullet-active {
    background: black;
  }
`;

const ProductInfo = styled.div`
  flex: 0 0 350px;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex: 1;
    padding: 0;
  }
`;

const ProductTitle = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 0;
  font-weight: 700;
`;

const BrandLink = styled(Link)`
  font-size: 16px;
  color: #666;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
`;

const StatusBox = styled.div`
  display: inline-block;
  padding: 8px 12px;
  background-color: #d3d3d3;
  color: #495057;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
`;

const Price = styled.div`
  font-size: 20px;
  color: #333;
  margin: 10px 0;
  font-weight: 700;
`;

const Description = styled.p`
  font-size: 14px;
  color: #555;
  line-height: 1.6;
  margin: 10px 0;
`;

const TagsLabel = styled.h3`
  font-size: 16px;
  color: #333;
  margin: 20px 0 10px;
  font-weight: 600;
`;

const Tag = styled.span`
  display: inline-block;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 15px;
  font-size: 12px;
  color: #333;
  margin-right: 5px;
`;

const SubcategoriesLabel = styled.h3`
  font-size: 16px;
  color: #333;
  margin: 20px 0 10px;
  font-weight: 600;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StoreLink = styled.a`
  font-size: 14px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  margin: 10px 0;
`;

const ItemDetail = () => {
  const location = useLocation();
  const [canGoBack, setCanGoBack] = useState(false);
  const navigate = useNavigate();
  const { item: initialItem } = location.state || {};
  const { item_purchase_id } = useParams();
  const [item, setItem] = useState(initialItem);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const swiperRef = React.useRef(null);

  const { items, setItems } = useContext(ItemsContext);
  const { userkey } = useContext(AuthContext);
  const { showToast } = useToast();

  const [isEditing, setIsEditing] = useState(false);
  const [itemName, setItemName] = useState(item ? item.name : "");
  const [itemBrand, setItemBrand] = useState(item ? item.brand : "");
  const [itemImageUrls, setItemImageUrls] = useState(
    item ? item.image_urls || [] : []
  );
  const [newImageUrl, setNewImageUrl] = useState("");
  const [itemStoreUrl, setItemStoreUrl] = useState(item ? item.store_url : "");
  const [itemColor, setItemColor] = useState(item ? item.color : "");
  const [itemType, setItemType] = useState(item ? item.item_type : "");
  const [itemSubcategories, setItemSubcategories] = useState(
    item && item.item_subcategory ? item.item_subcategory.split(",") : []
  );
  const [newSubcategory, setNewSubcategory] = useState("");

  const [itemFormalityTypes, setItemFormalityType] = useState(
    item && item.formality_type ? item.formality_type.split(":") : []
  );

  const itemTypeOptions = ["top", "bottom", "dress", "shoes", "accessories"];

  useEffect(() => {
    const fromRoute = location.state?.from;
    console.log(location.state);
    setCanGoBack(fromRoute === "/closet" || fromRoute === "/styleguide");
  }, [location.state]);

  useEffect(() => {
    if (!initialItem && userkey && item_purchase_id) {
      const fetchItem = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/user/${userkey}/item/${item_purchase_id}`
          );
          const data = await response.json();
          if (response.ok) {
            setItem(data);
          } else {
            throw new Error("Failed to fetch item.");
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchItem();
    }
  }, [initialItem, userkey, item_purchase_id]);

  useEffect(() => {
    if (item) {
      setItemImageUrls(item.image_urls || []);
    }
  }, [item]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!item) {
    return <div>Item not found</div>;
  }

  const handleSave = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/item_purchases/${item.purchase_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer your_token",
          },
          body: JSON.stringify({
            name: itemName,
            brand: itemBrand,
            color: itemColor,
            item_type: itemType || "top",
            image_urls: itemImageUrls,
            store_url: itemStoreUrl,
            item_subcategory: itemSubcategories.join(","),
          }),
        }
      );

      if (response.ok) {
        const updatedItem = {
          ...item,
          name: itemName,
          brand: itemBrand,
          color: itemColor,
          item_type: itemType,
          image_urls: itemImageUrls,
          store_url: itemStoreUrl,
          item_subcategory: itemSubcategories.join(","),
        };

        setItem(updatedItem);
        const updatedItems = items.map((i) =>
          i.purchase_id === item.purchase_id ? updatedItem : i
        );
        setItems(updatedItems);
        showToast(`Successfully updated ${itemName}.`);
        setIsEditing(false);
      } else {
        showToast("Failed to update the item.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("An error occurred while updating the item.", "error");
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleRemoveSubcategory = (subcategoryToRemove) => {
    setItemSubcategories(
      itemSubcategories.filter((sub) => sub !== subcategoryToRemove)
    );
  };

  const handleAddSubcategory = (e) => {
    if (e.key === "Enter" && newSubcategory.trim() !== "") {
      setItemSubcategories([...itemSubcategories, newSubcategory.trim()]);
      setNewSubcategory("");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/item_purchases/${item.purchase_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer your_token",
          },
        }
      );
      if (response.ok) {
        const updatedItems = items.filter((i) => i.item_id !== item.item_id);
        setItems(updatedItems);
        showToast(`Successfully deleted ${item.item_name} from your closet.`);
        navigate("/closet");
      } else {
        showToast("Failed to delete the item.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("An error occurred while deleting the item.", "error");
    }
  };

  const brandName = item.brand;
  const closetUrl = `/closet?brand=${brandName}`;

  return (
    <DetailContainer>
      {canGoBack && <BackButton onClick={() => navigate(-1)}>Back</BackButton>}
      <ContentWrapper>
        <ImageSection>
          <ThumbnailContainer>
            {item.image_urls && item.image_urls.map((url, index) => (
              <ThumbnailImage 
                key={index}
                src={encodeURI(url)}
                alt={`${item.name} thumbnail ${index + 1}`}
                style={{ 
                  opacity: selectedImageIndex === index ? 0.6 : 1,
                  border: selectedImageIndex === index ? '2px solid #000' : 'none'
                }}
                onClick={() => {
                  if (swiperRef.current) {
                    swiperRef.current.slideTo(index);
                  }
                  setSelectedImageIndex(index);
                }}
              />
            ))}
          </ThumbnailContainer>
          <MainImageContainer>
            <StyledSwiper
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              modules={[Navigation, Pagination]}
              spaceBetween={10}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              initialSlide={selectedImageIndex}
              onSlideChange={(swiper) => setSelectedImageIndex(swiper.activeIndex)}
            >
              {item.image_urls && item.image_urls.map((url, index) => (
                <SwiperSlide key={index}>
                  <MainImage
                    src={encodeURI(url)}
                    alt={`${item.name} - Image ${index + 1}`}
                  />
                </SwiperSlide>
              ))}
            </StyledSwiper>
          </MainImageContainer>
        </ImageSection>
        
        <ProductInfo>
          {isEditing ? (
            <>
              <label>
                Name:
                <InputField
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}
                  placeholder="Item Name"
                />
              </label>
              <label>
                Brand:
                <InputField
                  value={itemBrand}
                  onChange={(e) => setItemBrand(e.target.value)}
                  placeholder="Item Brand"
                />
              </label>
              <label>
                Color:
                <InputField
                  value={itemColor}
                  onChange={(e) => setItemColor(e.target.value)}
                  placeholder="Item Color"
                />
              </label>
              <label>
                Store site:
                <InputField
                  value={itemStoreUrl}
                  onChange={(e) => setItemStoreUrl(e.target.value)}
                  placeholder="Store URL"
                />
              </label>
              
              <div style={{ marginTop: '20px' }}>
                <h3>Image URLs:</h3>
                {itemImageUrls.map((url, index) => (
                  <div key={index} style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                    <InputField
                      value={url}
                      onChange={(e) => {
                        const updatedUrls = [...itemImageUrls];
                        updatedUrls[index] = e.target.value;
                        setItemImageUrls(updatedUrls);
                      }}
                      placeholder={`Image URL ${index + 1}`}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        const updatedUrls = itemImageUrls.filter((_, i) => i !== index);
                        setItemImageUrls(updatedUrls);
                      }}
                      style={{
                        padding: '4px 8px',
                        background: '#ff4444',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer'
                      }}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <InputField
                  value={newImageUrl}
                  onChange={(e) => setNewImageUrl(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && newImageUrl.trim() !== "") {
                      setItemImageUrls([...itemImageUrls, newImageUrl.trim()]);
                      setNewImageUrl("");
                    }
                  }}
                  placeholder="Add new image URL and press Enter"
                />
              </div>

              <label>
                Item type:
                <select
                  value={itemType}
                  onChange={(e) => setItemType(e.target.value)}
                >
                  {itemTypeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </label>
              
              <ChipContainer>
                {itemSubcategories.map((subcategory) => (
                  <Chip key={subcategory}>
                    {subcategory}
                    <ChipClose onClick={() => handleRemoveSubcategory(subcategory)}>
                      ×
                    </ChipClose>
                  </Chip>
                ))}
              </ChipContainer>
              <InputField
                value={newSubcategory}
                onChange={(e) => setNewSubcategory(e.target.value)}
                onKeyDown={handleAddSubcategory}
                placeholder="Add Subcategory and press Enter"
              />
              
              <ButtonGroup>
                <SaveButton onClick={handleSave}>Save Changes</SaveButton>
                <CancelButton onClick={handleEditToggle}>Cancel</CancelButton>
              </ButtonGroup>
            </>
          ) : (
            <>
              <ProductTitle>{item.name}</ProductTitle>
              <BrandLink to={closetUrl}>{item.brand}</BrandLink>
              {item.status && (
                <p><strong>Status:</strong> <StatusBox>{item.status}</StatusBox></p>
              )}
              {item.color && (
                <p><strong>Color:</strong> {item.color}</p>
              )}
              <p><strong>Category:</strong> {item.item_type}</p>
              
              <ChipContainer>
                {itemSubcategories.map((subcategory) => (
                  <Chip key={subcategory}>{subcategory}</Chip>
                ))}
                {itemFormalityTypes.map((formality_type) => (
                  <Chip key={formality_type}>{formality_type}</Chip>
                ))}
              </ChipContainer>
              
              <StoreLink href={item.store_url} target="_blank" rel="noopener noreferrer">
                View store link
              </StoreLink>

              <ButtonGroup>
                <DeleteButton onClick={handleDelete}>Delete from Closet</DeleteButton>
                <EditButton onClick={handleEditToggle}>Edit Item</EditButton>
              </ButtonGroup>
            </>
          )}
        </ProductInfo>
      </ContentWrapper>
    </DetailContainer>
  );
};

export default ItemDetail;