import styled from "styled-components";

// Base button styles for consistency
const BaseButton = styled.button`
  padding: 10px 20px; // Consistent padding
  color: white; // Text color
  border: none; // No border
  border-radius: 4px; // Rounded corners
  cursor: pointer; // Pointer cursor on hover
  font-size: 14px; // Font size
  width: 150px; // Set a fixed width for all buttons
  transition: background-color 0.3s; // Smooth transition for hover effect
`;

export const AddButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #218838;
  }
`;

export const BackButton = styled.button`
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

export const EditButton = styled(BaseButton)`
  background-color: rgba(0, 128, 0, 0.75); // Green with 75% transparency

  &:hover {
    background-color: rgba(0, 128, 0, 0.9); // Darker green on hover
  }
`;

export const DeleteButton = styled(BaseButton)`
  background-color: rgba(255, 0, 0, 0.75); // Red with 75% transparency

  &:hover {
    background-color: rgba(255, 0, 0, 0.9); // Darker red on hover
  }
`;

export const SaveButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

export const CancelButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #6c757d;
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #5a6268;
  }
`;
