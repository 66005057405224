import React from "react";
import { StytchLogin } from "@stytch/react";

const LoginForm = ({ prompt }) => {
  // `consent` forces user to grant permission to access their email
  // `select_account` forces account selection during sign-in
  const promptOption = prompt ? "consent" : "select_account";
  const stytchProps = {
    config: {
      products: ["oauth"],
      oauthOptions: {
        providers: [
          {
            type: "google",
            // one_tap: true,
            custom_scopes: [
              // "openid",
              // "email",
              // "profile",
              "https://www.googleapis.com/auth/gmail.readonly",
            ],
            provider_params: {
              // prompt: "select_account",
              prompt: promptOption, 
            },
          },
        ],
      },
    },
    styles: {
      container: { width: "321px" },
      colors: { primary: "#0577CA" },
      fontFamily: '"Helvetica New", Helvetica, sans-serif',
    },
  };

  return (
    <div className="sign-in-container">
      <StytchLogin config={stytchProps.config} styles={stytchProps.styles} />
    </div>
  );
};

export default LoginForm;
