// src/routes/NewOutfit.js
import React, { useContext, useState, useEffect } from "react";

import { AuthContext } from "../context/AuthProvider";
import { ItemsContext } from "../context/ItemsContext";
import { useLocation } from "react-router-dom";
import OutfitCreation from "../components/OutfitCreation";

const NewOutfit = () => {
  const { userkey } = useContext(AuthContext);
  const { items, loading, reloadItems, error } = useContext(ItemsContext);

  const location = useLocation();
  // console.log("NewOutfit route rendered", { userkey, items });

  useEffect(() => {
    if (!userkey || items.length > 0) return; // Avoid fetch if items already loaded

    reloadItems(userkey);
  }, [userkey, location.pathname]);

  if (items.length === 0 || loading) {
    return <div>Loading...</div>;
  }
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <OutfitCreation items={items} />
    </div>
  );
};

export default NewOutfit;
