import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import ItemCard from "../components/ItemCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddButton } from "./Buttons";

const ClosetWrapper = styled.div`
  display: flex;
  padding: 30px;
  max-width: 1600px;
  margin: 0 auto;
  height: calc(100vh - 80px);
  position: relative;

  @media (max-width: 768px) {
    padding: 20px;
    flex-direction: column;
    height: auto;
  }
`;

const SidebarContainer = styled.div`
  width: 180px;
  background-color: transparent;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  gap: 15px;
  padding-right: 30px;
  height: fit-content;

  @media (max-width: 768px) {
    width: 100%;
    padding-right: 0;
    padding-bottom: 20px;
    position: static;
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
`;

const SearchInput = styled.input`
  padding: 12px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
  width: 300px;
  font-family: inherit;
  background-color: white;

  &:focus {
    outline: none;
    border-color: #000;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

const CategoryButton = styled.button`
  width: 100%;
  padding: 8px 0;
  border: none;
  background-color: transparent;
  color: ${({ selected }) => (selected ? "#000" : "#666")};
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  font-weight: ${({ selected }) => (selected ? "500" : "400")};
  transition: color 0.2s ease;

  &:hover {
    color: #000;
    background-color: transparent;
  }
`;

const MainContentContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 40px;
  position: relative;
  
  h1 {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 20px;
    background-color: white;
    z-index: 10;
    padding-bottom: 15px;

    @media (max-width: 768px) {
      font-size: 20px;
      margin-bottom: 15px;
      position: static;
    }
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  background-color: white;
  z-index: 10;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    position: static;
    gap: 12px;
  }
`;

const FilterButton = styled.button`
  padding: 8px 16px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  
  &:after {
    content: "—";
    margin-left: 4px;
  }
`;

const ItemCount = styled.div`
  font-size: 14px;
  color: #666;
`;

const SortDropdown = styled.select`
  padding: 12px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
  width: 300px;
  font-family: inherit;
  color: #666;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E") no-repeat right 12px center;
  padding-right: 40px;

  &:focus {
    outline: none;
    border-color: #000;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 30px 25px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
  }
`;

const ImportedTag = styled.span`
  background-color: rgba(0, 0, 0, 0.05);
  color: #666;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: normal;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
  opacity: 0.15;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ClosetImage = styled.img`
  width: 70%;
  height: auto;
  max-width: 130px;
  margin: 0 auto;
`;

const categories = ["all", "top", "bottom", "dress", "shoes", "accessories"];

const Closet = ({ items }) => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(
    () => searchParams.get("search") || ""
  );
  const [sortOrder, setSortOrder] = useState("default");
  const navigate = useNavigate();

  // Filter items
  const filteredItems = items.filter((item) => {
    const matchesCategory =
      selectedCategory === "all" ||
      item.item_type?.toLowerCase() === selectedCategory;

    const searchTerms = searchQuery.toLowerCase().trim().split(/\s+/);
    
    const searchableFields = [
      item.name,
      item.brand,
      item.color,
      item.description,
      item.item_type,
      item.occasion,
      item.season
    ].map(field => (field || "").toLowerCase());

    const matchesSearchQuery = searchTerms.every(term =>
      searchableFields.some(field => field.includes(term))
    );

    return matchesCategory && matchesSearchQuery;
  });

  // Sort items
  const sortedItems = useMemo(() => {
    console.log('Attempting to sort items:', {
      firstItem: filteredItems[0],
      itemCount: filteredItems.length,
      sortOrder
    });

    let sorted = [...filteredItems];

    if (sortOrder === "brandAZ") {
      sorted.sort((a, b) => {
        console.log(`Comparing: ${a.brand} vs ${b.brand}`);
        return a.brand.localeCompare(b.brand);
      });
    } else if (sortOrder === "colorAZ") {
      sorted.sort((a, b) => a.color.localeCompare(b.color));
    } else if (sortOrder === "newest") {
      sorted.sort((a, b) => b.purchase_id - a.purchase_id);
    } else if (sortOrder === "oldest") {
      sorted.sort((a, b) => a.purchase_id - b.purchase_id);
    } else {
      sorted = sorted;
    }

    return sorted;
  }, [filteredItems, sortOrder]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <ClosetWrapper>
      <SidebarContainer>
        <div>
          <h2>Categories</h2>
          {categories.map((category) => (
            <CategoryButton
              key={category}
              onClick={() => setSelectedCategory(category)}
              selected={selectedCategory === category}
            >
              {category}
            </CategoryButton>
          ))}
        </div>
      </SidebarContainer>

      <MainContentContainer>
        <h1>My Wardrobe</h1>
        <ControlsContainer>
          <SearchInput
            type="text"
            placeholder='Search items... e.g., "black" or "sweater"'
            value={searchQuery}
            onChange={handleSearchChange}
          />
          
          <ItemCount>{filteredItems.length} ITEMS</ItemCount>
          
          <SortDropdown
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="default">Default Sort</option>
            <option value="brandAZ">Sort by Brand A-Z</option>
            <option value="colorAZ">Sort by Color A-Z</option>
            <option value="newest">Newest Added</option>
            <option value="oldest">Oldest Added</option>
          </SortDropdown>
          
          <AddButton onClick={() => navigate("/item/add")}>Add Items</AddButton>
        </ControlsContainer>

        {sortedItems.length === 0 ? (
          <div style={{
            textAlign: "center",
            padding: "60px 20px",
            color: "#666",
          }}>
            <h3 style={{ fontSize: "18px", marginBottom: "12px" }}>No Items Found</h3>
            <p style={{ fontSize: "14px", color: "#888" }}>
              Try adjusting your filters to find what you're looking for.
            </p>
          </div>
        ) : (
          <ItemGrid>
            {sortedItems.map((item) => (
              <ItemCard key={item.purchase_id} item={item} />
            ))}
          </ItemGrid>
        )}
      </MainContentContainer>
    </ClosetWrapper>
  );
};

export default Closet;
