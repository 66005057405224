// src/routes/StyleGuide.js
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { AddButton } from "../components/Buttons";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";

const mockFolders = ["Casual - Fall", "Work", "Japan Trip"];
// const mockOutfits = [
//   {
//     id: 1,
//     image: "path/to/image1.jpg",
//     tags: ["#School", "#Casual"],
//     items: [
//       { name: "White sleeveless top", link: "/item/1" },
//       { name: "Blue skirt", link: "/item/2" },
//       { name: "Sneakers", link: "/item/3" },
//     ],
//   },
//   {
//     id: 2,
//     image: "path/to/image2.jpg",
//     tags: ["#Casual", "#DateNight"],
//     items: [
//       { name: "Black crop top", link: "/item/4" },
//       { name: "White pants", link: "/item/5" },
//       { name: "Sandals", link: "/item/6" },
//     ],
//   },
//   // Add more mock outfits as needed
// ];

// Styled components for layout and styling
const Container = styled.div`
  display: flex;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  font-family: Arial, sans-serif;
`;

const Sidebar = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;
`;

const ContentArea = styled.div`
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 2em;
  margin: 0;
`;

const TagButton = styled.button`
  padding: 10px;
  background-color: ${({ selected }) => (selected ? "#ffd966" : "#f0f0f0")};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: #ffd966;
  }
`;

const OutfitGrid = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const OutfitCard = styled.div`
  width: 250px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  background-color: #fff;
`;

const OutfitImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const Tags = styled.div`
  margin: 10px 0;
  font-size: 0.9em;
  color: #666;
`;

const ItemLinks = styled.div`
  font-size: 0.9em;
  color: #007bff;
  text-align: left;
  padding-top: 10px;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyleGuide = () => {
  const { userkey } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedTag, setSelectedTag] = useState(null);
  const [outfits, setOutfits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    // Fetch outfits from the API
    const fetchOutfits = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/outfits/${userkey}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch outfits");
        }
        const data = await response.json();
        setOutfits(data || []);

        // Extract unique tags from outfits
        const uniqueTags = new Set();
        data.forEach((outfit) => {
          outfit.tags?.forEach((tag) => uniqueTags.add(tag));
        });
        setTags([...uniqueTags]);

        if (uniqueTags.size > 0) {
          setSelectedTag([...uniqueTags][0]); // Default to the first tag
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (userkey) {
      fetchOutfits();
    }
  }, [userkey]);

  const handleAddOutfitClick = () => {
    navigate("/outfit/new");
  };

  return (
    <Container>
      {/* Sidebar with Tag Navigation */}
      <Sidebar>
        {tags.map((tag) => (
          <TagButton
            key={tag}
            selected={tag === selectedTag}
            onClick={() => setSelectedTag(tag)}
          >
            {tag}
          </TagButton>
        ))}
      </Sidebar>

      {/* Main Content Area */}
      <ContentArea>
        <Header>
          <Title>Get Dressed!</Title>
          <AddButton onClick={handleAddOutfitClick}>Add Outfit</AddButton>
        </Header>

        {error && <p style={{ color: "red" }}>{error}</p>}
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <OutfitGrid>
              {outfits
                .filter((outfit) => outfit.tags?.includes(selectedTag))
                .map((outfit) => (
                  <OutfitCard key={outfit.id}>
                    <OutfitImage
                      src={outfit.image_url}
                      alt={`Outfit ${outfit.id}`}
                    />
                    {outfit.tags && outfit.tags.length > 0 && (
                      <Tags>Tags: {outfit.tags.join(", ")}</Tags>
                    )}
                    <h3>{outfit.name}</h3>
                    <ItemLinks>
                      <strong>In this outfit:</strong>
                      <ul>
                        {outfit.item_purchases.map((item) => (
                          <li key={item.purchase_id}>
                            <Link
                              to={`/item/${item.purchase_id}`}
                              state={{ item, from: "/styleguide" }}
                            >
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </ItemLinks>
                  </OutfitCard>
                ))}
            </OutfitGrid>
          </>
        )}
      </ContentArea>
    </Container>
  );
};

export default StyleGuide;
