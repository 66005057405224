import React, { useState } from 'react';
import styled from 'styled-components';
import celloLogo from '../assets/logo.png';
import { Link } from 'react-router-dom';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  border-bottom: 1px solid #eee;
`;

const MenuIcon = styled.div`
  cursor: pointer;
  font-size: 24px; // Adjust size for visibility
`;

const Logo = styled.img`
  height: 38px; // Adjust the size as needed
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 60px; // Adjust based on navbar height
  right: 0;
  background-color: white;
  border: 1px solid #eee;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px; // Set a width for the dropdown
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')}; // Show/hide based on state
`;

const MenuItem = styled(Link)`
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;

  &:hover {
    background-color: #f0f0f0; // Highlight on hover
  }
`;

const MobileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NavbarContainer>
      <MenuIcon onClick={toggleMenu}>☰</MenuIcon>
      <Logo src={celloLogo} alt="Cello Logo" />
      <MenuIcon>Login</MenuIcon>
      <DropdownMenu isOpen={isOpen}>
        <MenuItem to="/">Home</MenuItem>
        <MenuItem to="/closet">Closet</MenuItem>
        <MenuItem to="/insights">Insights</MenuItem>
        {/* Add more menu items as needed */}
      </DropdownMenu>
    </NavbarContainer>
  );
};

export default MobileNavbar;
