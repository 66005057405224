// src/components/OutfitCreation.js

import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthContext } from "../context/AuthProvider";
import { useToast } from "../components/Toast";
import { AddButton } from "./Buttons";

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  max-width: 900px;
  margin: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightColumn = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;

  @media (max-width: 768px) {
    padding-top: 30px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 15px;
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const CarouselItem = styled.div`
  text-align: center;
  width: 200px;
`;

const ArrowButton = styled.button`
  margin: 0 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #007bff;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const ItemName = styled.p`
  margin-top: 10px;
  font-size: 16px;
  color: #333;
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
`;

const Chip = styled.div`
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
`;

const ChipClose = styled.span`
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
`;

const filterItemsByType = (items, type) =>
  items.filter((item) => item.item_type?.toLowerCase() === type);

const OutfitCreation = ({ items }) => {
  const { userkey } = useContext(AuthContext);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [outfitName, setOutfitName] = useState("");

  const [tops, setTops] = useState(() => filterItemsByType(items, "top"));
  const [topIndex, setTopIndex] = useState(0);

  const [bottoms, setBottoms] = useState(() =>
    filterItemsByType(items, "bottom")
  );
  const [bottomIndex, setBottomIndex] = useState(0);

  const [shoes, setShoes] = useState(() => filterItemsByType(items, "shoes"));
  const [shoesIndex, setShoesIndex] = useState(0);

  const handleNext = (setter, items, index) => {
    setter((index + 1) % items.length);
  };

  const handlePrev = (setter, items, index) => {
    setter((index - 1 + items.length) % items.length);
  };

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");

  const handleAddTag = (e) => {
    if (e.key === "Enter" && newTag.trim() !== "") {
      setTags([...tags, newTag.trim()]);
      setNewTag("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  // todo: consider adding outfit context similar to item context
  const handleAddOutfit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/outfits/new`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userkey: userkey,
            name: outfitName || "default outfit name",
            color: "red",
            image_url: tops[topIndex].image_urls?.[0],
            tags: tags,
            item_purchase_ids: [
              tops[topIndex].purchase_id,
              bottoms[bottomIndex].purchase_id,
              shoes[shoesIndex].purchase_id,
            ],
          }),
        }
      );

      if (response.ok) {

        navigate("/styleguide");

        showToast(`Successfully added outfit!`);
      } else {
        showToast("Failed to add the outfit.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("An error occurred while adding the outfit.", "error");
    }
  };

  return (
    <PageContainer>
      {/* Left Column - Outfit Pickers */}
      <LeftColumn>
        {/* Top/Dress Picker */}
        <CarouselContainer>
          <h2>Top / Dress</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowButton
              onClick={() => handlePrev(setTopIndex, tops, topIndex)}
            >
              &#8592;
            </ArrowButton>
            <CarouselItem>
              <Image
                src={encodeURI(tops[topIndex].image_urls?.[0])}
                alt={tops[topIndex].name}
              />
              <ItemName>{tops[topIndex].name}</ItemName>
            </CarouselItem>
            <ArrowButton
              onClick={() => handleNext(setTopIndex, tops, topIndex)}
            >
              &#8594;
            </ArrowButton>
          </div>
        </CarouselContainer>

        {/* Bottom Picker */}
        <CarouselContainer>
          <h2>Bottom</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowButton
              onClick={() => handlePrev(setBottomIndex, bottoms, bottomIndex)}
            >
              &#8592;
            </ArrowButton>
            <CarouselItem>
              <Image
                src={encodeURI(bottoms[bottomIndex].image_urls?.[0])}
                alt={bottoms[bottomIndex].name}
              />
              <ItemName>{bottoms[bottomIndex].name}</ItemName>
            </CarouselItem>
            <ArrowButton
              onClick={() => handleNext(setBottomIndex, bottoms, bottomIndex)}
            >
              &#8594;
            </ArrowButton>
          </div>
        </CarouselContainer>

        {/* Shoes Picker */}
        <CarouselContainer>
          <h2>Shoes</h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowButton
              onClick={() => handlePrev(setShoesIndex, shoes, shoesIndex)}
            >
              &#8592;
            </ArrowButton>
            <CarouselItem>
              <Image
                src={encodeURI(shoes[shoesIndex].image_urls?.[0])}
                alt={shoes[shoesIndex].name}
              />
              <ItemName>{shoes[shoesIndex].name}</ItemName>
            </CarouselItem>
            <ArrowButton
              onClick={() => handleNext(setShoesIndex, shoes, shoesIndex)}
            >
              &#8594;
            </ArrowButton>
          </div>
        </CarouselContainer>
      </LeftColumn>

      {/* Right Column - Outfit Name & Add Button */}
      <RightColumn>
        <Title>Create Your Outfit</Title>
        <InputField
          type="text"
          placeholder="Enter outfit name..."
          value={outfitName}
          onChange={(e) => setOutfitName(e.target.value)}
        />

        {/* Tags Input */}
        <h3>Tags</h3>
        <ChipContainer>
          {tags.map((tag) => (
            <Chip key={tag}>
              {tag}
              <ChipClose onClick={() => handleRemoveTag(tag)}>x</ChipClose>
            </Chip>
          ))}
          <InputField
            type="text"
            placeholder="Add tag and press Enter"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onKeyDown={handleAddTag}
          />
        </ChipContainer>
        <AddButton onClick={handleAddOutfit}>Add Outfit</AddButton>
      </RightColumn>
    </PageContainer>
  );
};

export default OutfitCreation;
