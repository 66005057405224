import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import productImage from '../assets/outfits.jpeg';
import celloLogo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import mainImage from '../assets/happy_v2.jpg';
import secondImageshaped from '../assets/shaped_digitized.png';
import thirdImageshaped from '../assets/shaped_outfits.png';
import sampleVideo from '../assets/Closetdemo.mp4';
import sampleVideo2 from '../assets/Outfits.mp4';
import packingimage from '../assets/packing.png';

const fadeInLeftToRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInRightToLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const scrollAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  10% {
    opacity: 1;
    transform: translateY(0);
  }
  90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
`;

// Container and Section Styles
const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FF8650;
  height: 80vh;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const TextSection = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  color: #fff;
  padding-left: 60px;
  animation: ${fadeInLeftToRight} 1s ease-in-out forwards;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    align-items: center;
    text-align: center;
  }
`;

const TextBoxSubHeading = styled.p`
  font-size: 1.2em;
  margin-top: 10px;
  color: #fff;
`;

const CircularImage = styled.div`
  width: 70%;
  height: 80vh;
  background-image: url(${mainImage});
  background-size: cover;
  background-position: left;
  border-radius: 100% 0 0 100%;
  animation: ${fadeInRightToLeft} 1s ease-in-out forwards;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Heading = styled.h1`
  font-size: 3.5em;
  margin: 0 0 20px;
`;

const StyledButton = styled.button`
  padding: 10px 25px;
  font-size: 1em;
  color: #333;
  background-color: #FDE293;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 50px;
  width: 380px;

  &:hover {
    background-color: #fcd54f;
  }
`;

const SecondContainer = styled.div`
  background-color: #FFFFFF;
  padding: 50px 20px;
  text-align: center;
`;

const SecondHeading = styled.h2`
  color: #000000;
  font-size: 2em;
  margin-bottom: 20px;
`;
const SecondSubHeading = styled.p`
  color: #000000;
  font-size: 1.8em;
  margin-bottom: 10px;
  text-align: center;
`;

const ChangingWordWrapper = styled.div`
  display: inline-block;
  width: 300px; /* Increased width further to prevent text cutoff */
  height: 1.1em;
  overflow: hidden;
  text-align: left;
  position: relative; /* For absolute positioning of changing word */
`;

const ChangingWord = styled.span`
  position: absolute;
  left: 0;
  animation: ${scrollAnimation} 1.5s infinite;
  white-space: nowrap;
  text-decoration: underline;
  color: #FF8650;
  text-align: left;
  line-height: 1.4; /* Match container line height */
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

const TopSection = styled.div`
  display: flex;
  width: 100%;
  height: 50vh;
`;

const LeftSection = styled.div`
  width: 50%;
  background-color: #FF8650;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const ImageContainer = styled.div`
  width: 80%;
  height: 80%;
  background-image: url(${productImage});
  background-size: cover;
  background-position: center;
  border-radius: 0; /* Removed rounded edges */
  overflow: hidden;
`;

const RightSection = styled.div`
  width: 50%;
  background-color: #FF8650;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 20px;
  border-radius: 20px; /* Added rounded edges */
`;

const Logo = styled.img`
  width: 200px;
  margin-bottom: 20px;
`;

const Tagline = styled.p`
  font-size: 1.5rem;
  margin-bottom: 30px;
`;

const StyledLink = styled(Link)`
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #333;
  background-color: #eac3fc;
  border: none;
  border-radius: 30px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #dba7fa;
  }
`;
const BottomSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: rgb(234, 234, 202);
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 30px 20px;
  }
`;
const CardContainer = styled.div`
  display: flex;
  gap: 50px; // Reduced gap from 80px to 50px
  max-width: 1600px; // Increased max-width
  width: 90%; // Use percentage instead of 100% to avoid touching edges
  justify-content: space-between; // Changed to space-between for better spread

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;

const Card = styled.div`
  width: 50%;
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const CardImage = styled.img`
  width: 80%;
  height: 80%;
  object-fit: cover;
  background-position: center;
`;

const CardContent = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const CardText = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
`;

const ArrowButton = styled.div`
  width: 40px;
  height: 40px;
  border: 2px solid white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const words = ['stylist', 'outfit planner', 'packing assistant'];

const SplashPage = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <MainContainer>
        <TextSection>
          <Heading><i>Cello: </i>mindful fashion, joyful dressing</Heading>
          <Link to="https://n8ikarwap6j.typeform.com/to/X9kn3zqi">
            <StyledButton>Join the waitlist</StyledButton>
          </Link>
          <TextBoxSubHeading>Get exclusive first access and a free styling lookbook on us</TextBoxSubHeading>
        </TextSection>
        <CircularImage />
      </MainContainer>

      <SecondContainer>
        <SecondSubHeading>Figuring out what to wear is hard.</SecondSubHeading>
        <SecondHeading>
          Cello is your daily, on-the-go{' '}
          <ChangingWordWrapper>
            <ChangingWord key={currentWordIndex}>
              {words[currentWordIndex]}
            </ChangingWord>
          </ChangingWordWrapper>
        </SecondHeading>
      </SecondContainer>
      <BottomSection>
        <CardContainer>
          <Card>
            <CardImage src={sampleVideo} alt="Closet" as="video" autoPlay muted loop />
            <CardContent color="#d66b47">
              <CardText>Curate your digital closet in 20 minutes</CardText>
              <ArrowButton>→</ArrowButton>
            </CardContent>
          </Card>
          <Card>
            <CardImage src={sampleVideo2} alt="Styling" as="video" autoPlay muted loop />
            <CardContent color="#eac3fc">
              <CardText>Get hyper-personalized styling</CardText>
              <ArrowButton>→</ArrowButton>
            </CardContent>
          </Card>
          <Card>
            <CardImage src={packingimage} alt="Packing" />
            <CardContent color="#f4a261">
              <CardText>Plan and pack ahead</CardText>
              <ArrowButton>→</ArrowButton>
            </CardContent>
          </Card>
        </CardContainer>
      </BottomSection>
    </>
  );
};

export default SplashPage;
