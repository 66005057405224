import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";
import { AuthContext } from "../context/AuthProvider";
import { ItemsContext } from '../context/ItemsContext';
import styled from 'styled-components';

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  flex-shrink: 0;
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 768px) {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
`;

const NavItem = styled.a`
  font-size: 16px;
  font-family: inherit;
  color: #666;
  text-decoration: none;
  padding: 10px 0;

  &:hover {
    color: #000;
  }
`;

function Navbar() {
  const { isLoggedIn, signOut } = useContext(AuthContext);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const { clearItems } = useContext(ItemsContext);
  const fullySignOut = () => {
    signOut();
    clearItems();
  };

  return (
    <div>
      {isDesktopOrLaptop ? (
        <DesktopNavbar isLoggedIn={isLoggedIn} signOut={fullySignOut} />
      ) : (
        <MobileNavbar />
      )}
    </div>
  );
}

export default Navbar;
