import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: #f8f8f8;
  overflow: hidden;
  aspect-ratio: 3/4;

  &:hover {
    img {
      transform: scale(1.05);
    }
    
    .overlay {
      opacity: 0.1;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.6s ease;
  }
`;

const ContentContainer = styled.div`
  text-align: left;

  .brand {
    font-size: 13px;
    color: #666;
    margin-bottom: 6px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .name {
    font-size: 14px;
    color: #000;
    margin: 0;
    font-weight: 400;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
`;

const Tag = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  color: black;
  padding: 6px 10px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  z-index: 2;
`;

const NewTag = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: black;
  color: white;
  padding: 6px 10px;
  font-size: 10px;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  z-index: 2;
`;

const ImportedTag = styled.span`
  background-color: rgba(0, 0, 0, 0.05);
  color: #666;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: normal;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ItemCard = ({ item }) => {
  return (
    <Card>
      <Link to={`/item/${item.purchase_id}`} state={{ item, from: "/closet" }}>
        <ImageContainer>
          {item.source === "EMAIL" && (
            <Tag>imported from email</Tag>
          )}
          <NewTag>NEW</NewTag>
          <img src={encodeURI(item.image_urls?.[0])} alt={item.name} />
          <Overlay className="overlay" />
        </ImageContainer>
        <ContentContainer>
          <div className="brand">{item.brand}</div>
          <div className="name">{item.name}</div>
        </ContentContainer>
      </Link>
    </Card>
  );
};

export default ItemCard;
